<template>
  <div>
    <div class="mb-1">
      <b-row
        class="text-center font-weight-bold"
      >
        <b-col
          cols="12"
        >
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-danger"
            block
            disabled
          >
            Talebe Uygun Yönlendirmeler
          </b-button>
        </b-col>
      </b-row>
    </div>
    <div>
      <b-row>
        <!-- Eşleşme Süreç Sonuçları -->
        <b-col
          cols="12"
          md="6"
        >
          <!-- başlık -->
          <b-row class="border text-center font-weight-bold">
            <b-col>
              Süreç Sonuçları
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="8"
              md="8"
              class="text-danger border"
            >
              Eşlenen Aday
            </b-col>
            <b-col
              cols="4"
              md="4"
              class="border"
            >
              {{ tblbaslik.eslesen }}
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="8"
              md="8"
              class="text-danger border"
            >
              Yönlendirilen Aday
            </b-col>
            <b-col
              cols="4"
              md="4"
              class="border"
            >
              {{ tblbaslik.yonlendirilen }}
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="8"
              md="8"
              class="text-danger border"
            >
              Olumlu Aday
            </b-col>
            <b-col
              cols="4"
              md="4"
              class="border"
            >
              {{ tblbaslik.olumlu }}
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="8"
              md="8"
              class="text-danger border"
            >
              Olumsuz Aday
            </b-col>
            <b-col
              cols="4"
              md="4"
              class="border"
            >
              {{ tblbaslik.olumsuz }}
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="8"
              md="8"
              class="text-danger border"
            >
              Bekleyen Aday
            </b-col>
            <b-col
              cols="4"
              md="4"
              class="border"
            >
              {{ tblbaslik.bekleyen }}
            </b-col>
          </b-row>
        </b-col>
        <!-- Eşleşme Talep Uyum Sonuçları -->
        <b-col
          cols="12"
          md="6"
        >
          <!-- başlık -->
          <b-row class="border text-center font-weight-bold">
            <b-col>
              Talebe Yönlendirilenlerin Uyum Oranları
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="8"
              md="8"
              class="text-danger border"
            >
              Kişisel Yeterlilik Uyum
            </b-col>
            <b-col
              cols="4"
              md="4"
              class="border"
            >
              {{ oran(tblbaslik.kyu, tblbaslik.reqkyu) }}
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="8"
              md="8"
              class="text-danger border"
            >
              Mesleki Yeterlilik Uyum
            </b-col>
            <b-col
              cols="4"
              md="4"
              class="border"
            >
              {{ oran(tblbaslik.myu,tblbaslik.reqmyu) }}
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="8"
              md="8"
              class="text-danger border"
            >
              Temel Yetenek Uyum
            </b-col>
            <b-col
              cols="4"
              md="4"
              class="border"
            >
              {{ oran(tblbaslik.temyu,tblbaslik.reqtem) }}
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="8"
              md="8"
              class="text-danger border"
            >
              Teknik Yeterlilik Uyum
            </b-col>
            <b-col
              cols="4"
              md="4"
              class="border"
            >
              {{ oran(tblbaslik.tekyu,tblbaslik.reqtek) }}
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="8"
              md="8"
              class="text-danger border"
            >
              Risk Alma Uyum
            </b-col>
            <b-col
              cols="4"
              md="4"
              class="border"
            >
              {{ oran(tblbaslik.rsku,tblbaslik.reqrsk) }}
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <br><br>
    </div>
    <br>
    <!-- YÖNLENDİRME YAPILMIŞ ADAYLAR LİSTESİ BAŞLIK -->
    <div class="mb-1">
      <b-row
        class="text-center font-weight-bold"
      >
        <b-col
          cols="12"
        >
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-danger"
            block
            disabled
          >
            YÖNLENDİRME YAPILMIŞ ADAYLAR LİSTESİ
          </b-button>
        </b-col>
      </b-row>
    </div>
    <!-- YÖNLENDİRME YAPILMIŞ ADAYLAR LİSTESİ -->
    <div class="cols-12">
      <b-row cols="12"> <!-- filtre satırı -->
        <b-col md="4">
          <b-form-group
            label="Sıralama Seçeneği"
            label-for="sorting"
          >
            <v-select
              id="sorting"
              v-model="sortSelected"
              label="title"
              :options="sortOption"
              :reduce="item => item.value"
              class="border"
            />
          </b-form-group>
        </b-col>
        <b-col
          class="mt-2"
          md="6"
        >
          <b-form-checkbox
            v-model="sonuc"
            :value="true"
            plain
          >
            Sonuç Kaydı Olanlar
          </b-form-checkbox>
        </b-col>
      </b-row>
      <!-- AKTİF ADAY -->
      <b-row
        v-if="gelen.filter(x=>x.evaluationState ===1 && x.redirectionState ===1 || x.redirectionState ===2).filter(x => x.applicant.status === 1).filter(s=> s.resultState === 5 || s.resultState === 6 || s.resultState === 7 || s.resultState === 1 || s.resultState === 0).length >= 1"
      >
        <b-col
          cols="12"
          class="border font-weight-bold bg-success mb-1"
        >
          <label
            class="text-white"
            style="padding-top: 5px"
          >AKTİF ADAYLAR (Durumu Pasif olan Adaylar Listelenmez) </label>
        </b-col>
      </b-row>
      <b-row
        v-for="(ra,ix) in gelen.filter(x=> x.redirectionState ===1 || x.redirectionState ===2)
          .filter(x => x.applicant.status === 1).filter(x => x.resultState ===1 || x.resultState ===5 || x.resultState ===6 || x.resultState ===7 || (x.resultState ===0 && !sonuc))"
        :key="ix"
        class="border mb-1 border-success"
        align-v="center"
      >
        <b-col
          cols="12"
          md="1"
        >
          <b-row
            align-h="center"
          >
            <img
              :src="ra.applicant.photo === '' ? require('@/assets/images/usernoImage.png') : ra.applicant.photo"
              width="80"
              height="80"
              class="mb-1"
            >
          </b-row>
          <b-row
            align-h="center"
          >
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              size="sm"
              squared
              variant="relief-primary"
              @click="onAdayBtnClicked(ra)"
            >
              <feather-icon
                icon="UserIcon"
                class="mr-30"
              />
              <span>Özgeçmiş</span>
            </b-button>
          </b-row>
          <br>
          <b-row
            align-h="center"
          >
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              size="sm"
              squared
              variant="outline-danger"
              @click="onCvPuanBtnClicked(ra)"
            >
              <feather-icon
                icon="BookOpenIcon"
                class="mr-30"
              />
              <span class="ml-1">CV Puan</span>
            </b-button>
          </b-row>
        </b-col>

        <b-col md="9">
          <b-row>
            <!-- ilk col -->
            <b-col
              cols="12"
              md="4"
            >
              <b-row>
                <b-col
                  class="border text-danger"
                  cols="5"
                  md="5"
                >
                  Adı Soyadı:
                </b-col>
                <b-col
                  class="border"
                  cols="7"
                  md="7"
                >
                  {{ ra.applicant.applicantName }}
                </b-col>
              </b-row>
              <b-row>
                <b-col
                  class="border text-danger"
                  cols="5"
                  md="5"
                >
                  E-mail:
                </b-col>
                <b-col
                  class="border"
                  cols="7"
                  md="7"
                >
                  {{ ra.applicant.applicantEmail }}
                </b-col>
              </b-row>
              <b-row>
                <b-col
                  class="border text-danger"
                  cols="5"
                  md="5"
                >
                  Mezuniyet:
                </b-col>
                <b-col
                  class="border"
                  cols="7"
                  md="7"
                >
                  {{ ra.applicant.educationLevel }}
                </b-col>
              </b-row>
              <b-row>
                <b-col
                  class="border text-danger"
                  cols="5"
                  md="5"
                >
                  İkametgah İl:
                </b-col>
                <b-col
                  class="border"
                  cols="7"
                  md="7"
                >
                  {{ ra.applicant.city }}
                </b-col>
              </b-row>
              <b-row>
                <b-col
                  class="border text-danger"
                  cols="5"
                  md="5"
                >
                  Yönlendirme Tarihi:
                </b-col>
                <b-col
                  class="border"
                  cols="7"
                  md="7"
                >
                  {{ strToDate(ra.redirectionDate) }}
                </b-col>
              </b-row>
              <b-row>
                <b-col
                  class="border text-danger"
                  cols="5"
                  md="5"
                >
                  Yönlendirme Türü:
                </b-col>
                <b-col
                  class="border"
                  cols="7"
                  md="7"
                >
                  {{ ra.redirectionState == 1 ? "Yönlendirilmiş Aday" : "Şartlı Yönlendirilmiş Aday" }}
                </b-col>
              </b-row>
            </b-col>
            <!-- ikinci col -->
            <b-col
              cols="12"
              md="4"
            >
              <b-row>
                <b-col
                  class="border text-danger"
                  cols="7"
                  md="7"
                >
                  Telefon:
                </b-col>
                <b-col
                  class="border"
                  cols="5"
                  md="5"
                >
                  {{ ra.applicant.applicantPhone }}
                </b-col>
              </b-row>
              <b-row>
                <b-col
                  class="border text-danger"
                  cols="7"
                  md="7"
                >
                  Kayıt Tarihi:
                </b-col>
                <b-col
                  class="border"
                  cols="5"
                  md="5"
                >
                  {{ strToDate(ra.applicant.registrationDate) }}
                </b-col>
              </b-row>
              <b-row>
                <b-col
                  class="border text-danger"
                  cols="7"
                  md="7"
                >
                  Bölüm:
                </b-col>
                <b-col
                  class="border"
                  cols="5"
                  md="5"
                >
                  {{ ra.applicant.education }}
                </b-col>
              </b-row>
              <b-row>
                <b-col
                  class="border text-danger"
                  cols="7"
                  md="7"
                >
                  İkametgah İlçe:
                </b-col>
                <b-col
                  class="border"
                  cols="5"
                  md="5"
                >
                  {{ ra.applicant.town }}
                </b-col>
              </b-row>
              <b-row>
                <b-col
                  class="border text-danger"
                  cols="7"
                  md="7"
                >
                  Yönlendirme Sayısı:
                </b-col>
                <b-col
                  class="border"
                  cols="5"
                  md="5"
                >
                  {{ ra.applicant.redirectionCount }}
                </b-col>
              </b-row>
              <b-row>
                <b-col
                  class="border text-danger"
                  cols="7"
                  md="7"
                >
                  Görüşme Sonucu:
                </b-col>
                <b-col
                  class="border"
                  cols="5"
                  md="5"
                >
                  {{ getResultText(ra.resultState) }}
                </b-col>
              </b-row>
            </b-col>
            <!-- Üçüncü col  -->
            <b-col
              cols="12"
              md="4"
            >
              <b-row>
                <b-col
                  class="border text-danger"
                  cols="8"
                  md="8"
                >
                  Kişisel Özellik &nbsp; / &nbsp; Talep Oranı :
                </b-col>
                <b-col
                  class="border"
                  cols="4"
                  md="4"
                >
                  {{ ra.totalPersonalScore.toFixed(2) }} &nbsp;/ &nbsp;  {{ oran(ra.totalPersonalScore,r.evaluationStaffRequestSummaryViewModel.requestPersonalScore) }}
                </b-col>
              </b-row>
              <b-row>
                <b-col
                  class="border text-danger"
                  cols="8"
                  md="8"
                >
                  Mesleki Yeterlilik &nbsp; / &nbsp; Talep Oranı :
                </b-col>
                <b-col
                  class="border"
                  cols="4"
                  md="4"
                >
                  {{ ra.totalVocationalCompilanceScore.toFixed(2) }} &nbsp;/&nbsp;  {{ oran(ra.totalVocationalCompilanceScore, r.evaluationStaffRequestSummaryViewModel.requestVocationalCompilanceScore) }}
                </b-col>
              </b-row>
              <b-row>
                <b-col
                  class="border text-danger"
                  cols="8"
                  md="8"
                >
                  Temel Yetenek &nbsp; / &nbsp; Talep Oranı :
                </b-col>
                <b-col
                  class="border"
                  cols="4"
                  md="4"
                >
                  {{ ra.totalBasicAbilityScore.toFixed(2) }} &nbsp;/&nbsp;  {{ oran(ra.totalBasicAbilityScore, r.evaluationStaffRequestSummaryViewModel.requestBasicAbilityScore) }}
                </b-col>
              </b-row>
              <b-row>
                <b-col
                  class="border text-danger"
                  cols="8"
                  md="8"
                >
                  Teknik Yeterlilik &nbsp;&nbsp; / &nbsp;&nbsp; Talep Oranı :
                </b-col>
                <b-col
                  class="border"
                  cols="4"
                  md="4"
                >
                  {{ ra.totalTechnicalCompilanceScore.toFixed(2) }} &nbsp;/&nbsp;  {{ oran(ra.totalTechnicalCompilanceScore, r.evaluationStaffRequestSummaryViewModel.requestTechnicalCompilanceScore) }}
                </b-col>
              </b-row>
              <b-row>
                <b-col
                  class="border text-danger"
                  cols="8"
                  md="8"
                >
                  Risk &nbsp; / &nbsp; Talep Risk Oranı :
                </b-col>
                <b-col
                  class="border"
                  cols="4"
                  md="4"
                >
                  {{ ra.riskScore.toFixed(2) }} &nbsp;/&nbsp;  {{ oran(ra.riskScore, r.evaluationStaffRequestSummaryViewModel.requestRiskScore) }}
                </b-col>
              </b-row>
              <b-row>
                <b-col
                  class="border text-danger"
                  cols="8"
                  md="8"
                >
                  Karar Tarihi:
                </b-col>
                <b-col
                  class="border"
                  cols="4"
                  md="4"
                >
                  {{ strToDate(rDate) }}
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <!-- Not -->
          <b-row
            v-if="ra.redirectionState==1 || ra.redirectionState==2"
            class="border"
          >
            <b-col>
              <b-row class="text-left border">
                <b-col v-if="ra.redirectionState==1 || ra.redirectionState==2">
                  <label class="text-danger"> Yönlendirme Notu: </label>
                </b-col>
              </b-row>
              <b-row class="text-left border text-small">
                <b-col v-if="ra.redirectionState==1 || ra.redirectionState==2">
                  <p style="font-size:10px;">
                    {{ ra.redirectionNote }}
                  </p>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <!-- Olumsuz Neden -->
          <b-row>
            <b-col
              v-if="ra.redirectionState==1 || ra.redirectionState==2"
              class=" border"
              cols="6"
              md="4"
            >
              <label class="text-danger"> Olumsuz Nedeni: </label>
            </b-col>
            <b-col
              class="border"
            >
              {{ ra.resultNegativeText }}
            </b-col>
          </b-row>
        </b-col>
        <b-col md="2">
          <b-form-textarea
            id="e-textarea"
            v-model="ra.redirectionNote"
            type="text"
            placeholder="Not Yaz"
            rows="3"
            max-rows="2"
          />
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            size="sm"
            squared
            block
            class="mb-1"
            variant="relief-secondary"
            @click="onModalSaveNote(ra)"
          >
            <feather-icon
              icon="ArrowUpRightIcon"
              class="mr-30"
            />
            <span>Notu Kaydet </span>
          </b-button>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            size="sm"
            squared
            block
            class="mb-1"
            variant="relief-warning"
            @click="onKarar(ra)"
          >
            <feather-icon
              icon="ArrowUpRightIcon"
              class="mr-30"
            />
            <span>Karar </span>
          </b-button>
          <b-button
            v-if="ra.redirectionState==2"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            size="sm"
            squared
            block
            class="mb-1"
            variant="relief-primary"
            @click="onElenmeOzet(ra)"
          >
            <feather-icon
              icon="ArrowUpRightIcon"
              class="mr-30"
            />
            <span>Elenme Özeti </span>
          </b-button>
        </b-col>
      </b-row>
      <!-- PASİF ADAY -->
      <b-row
        v-if="gelen.filter(x=>x.evaluationState ===1 && x.redirectionState ===1 || x.redirectionState ===2).filter(x => x.applicant.status === 1).filter(x=>x.evaluationState ===1 && x.redirectionState ===1 || x.redirectionState ===2).filter(x => x.applicant.status === 1).filter(s => s.resultState === 2 || s.resultState === 3 || s.resultState === 4 || s.resultState === 8 ).length >= 1"
      >
        <b-col
          cols="12"
          class="border font-weight-bold bg-danger mb-1"
        >
          <label
            class="text-white"
            style="padding-top: 5px"
          >OLUMSUZ GÖRÜLEN VEYA SÜRECİ PASİFE ALINAN ADAYLAR (Durum Pasif olan adaylar listelenmez) </label>
        </b-col>
      </b-row>
      <b-row
        v-for="(ra,px) in gelen.filter(x=>x.evaluationState ===1 && x.redirectionState ===1 || x.redirectionState ===2)
          .filter(x => x.applicant.status === 1).filter(s => s.resultState === 2 || s.resultState === 3 || s.resultState === 4 || s.resultState === 8)"
        :key="px.key"
        class="border mb-1 border-danger"
        align-v="center"
      >
        <b-col
          cols="12"
          md="1"
        >
          <b-row
            align-h="center"
          >
            <img
              :src="ra.applicant.photo === '' ? require('@/assets/images/usernoImage.png') : ra.applicant.photo"
              width="80"
              height="80"
              class="mb-1"
            >
          </b-row>
          <b-row
            align-h="center"
          >
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              size="sm"
              squared
              variant="relief-primary"
              @click="onAdayBtnClicked(ra)"
            >
              <feather-icon
                icon="UserIcon"
                class="mr-30"
              />
              <span>Özgeçmiş</span>
            </b-button>
          </b-row>
          <br>
          <b-row
            align-h="center"
          >
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              size="sm"
              squared
              variant="outline-danger"
              @click="onCvPuanBtnClicked(ra)"
            >
              <feather-icon
                icon="BookOpenIcon"
                class="mr-30"
              />
              <span class="ml-1">CV Puan</span>
            </b-button>
          </b-row>
        </b-col>

        <b-col md="9">
          <b-row>
            <!-- ilk col -->
            <b-col
              cols="12"
              md="4"
            >
              <b-row>
                <b-col
                  class="border text-danger"
                  cols="5"
                  md="5"
                >
                  Adı Soyadı:
                </b-col>
                <b-col
                  class="border"
                  cols="7"
                  md="7"
                >
                  {{ ra.applicant.applicantName }}
                </b-col>
              </b-row>
              <b-row>
                <b-col
                  class="border text-danger"
                  cols="5"
                  md="5"
                >
                  E-mail:
                </b-col>
                <b-col
                  class="border"
                  cols="7"
                  md="7"
                >
                  {{ ra.applicant.applicantEmail }}
                </b-col>
              </b-row>
              <b-row>
                <b-col
                  class="border text-danger"
                  cols="5"
                  md="5"
                >
                  Mezuniyet:
                </b-col>
                <b-col
                  class="border"
                  cols="7"
                  md="7"
                >
                  {{ ra.applicant.educationLevel }}
                </b-col>
              </b-row>
              <b-row>
                <b-col
                  class="border text-danger"
                  cols="5"
                  md="5"
                >
                  İkametgah İl:
                </b-col>
                <b-col
                  class="border"
                  cols="7"
                  md="7"
                >
                  {{ ra.applicant.city }}
                </b-col>
              </b-row>
              <b-row>
                <b-col
                  class="border text-danger"
                  cols="5"
                  md="5"
                >
                  Yönlendirme Tarihi:
                </b-col>
                <b-col
                  class="border"
                  cols="7"
                  md="7"
                >
                  {{ strToDate(ra.redirectionDate) }}
                </b-col>
              </b-row>
              <b-row>
                <b-col
                  class="border text-danger"
                  cols="5"
                  md="5"
                >
                  Yönlendirme Türü:
                </b-col>
                <b-col
                  class="border"
                  cols="7"
                  md="7"
                >
                  {{ ra.redirectionState == 1 ? "Yönlendirilmiş Aday" : "Şartlı Yönlendirilmiş Aday" }}
                </b-col>
              </b-row>
            </b-col>
            <!-- ikinci col -->
            <b-col
              cols="12"
              md="4"
            >
              <b-row>
                <b-col
                  class="border text-danger"
                  cols="7"
                  md="7"
                >
                  Telefon:
                </b-col>
                <b-col
                  class="border"
                  cols="5"
                  md="5"
                >
                  {{ ra.applicant.applicantPhone }}
                </b-col>
              </b-row>
              <b-row>
                <b-col
                  class="border text-danger"
                  cols="7"
                  md="7"
                >
                  Kayıt Tarihi:
                </b-col>
                <b-col
                  class="border"
                  cols="5"
                  md="5"
                >
                  {{ strToDate(ra.applicant.registrationDate) }}
                </b-col>
              </b-row>
              <b-row>
                <b-col
                  class="border text-danger"
                  cols="7"
                  md="7"
                >
                  Bölüm:
                </b-col>
                <b-col
                  class="border"
                  cols="5"
                  md="5"
                >
                  {{ ra.applicant.education }}
                </b-col>
              </b-row>
              <b-row>
                <b-col
                  class="border text-danger"
                  cols="7"
                  md="7"
                >
                  İkametgah İlçe:
                </b-col>
                <b-col
                  class="border"
                  cols="5"
                  md="5"
                >
                  {{ ra.applicant.town }}
                </b-col>
              </b-row>
              <b-row>
                <b-col
                  class="border text-danger"
                  cols="7"
                  md="7"
                >
                  Yönlendirme Sayısı:
                </b-col>
                <b-col
                  class="border"
                  cols="5"
                  md="5"
                >
                  {{ ra.applicant.redirectionCount }}
                </b-col>
              </b-row>
              <b-row>
                <b-col
                  class="border text-danger"
                  cols="7"
                  md="7"
                >
                  Görüşme Sonucu:
                </b-col>
                <b-col
                  class="border"
                  cols="5"
                  md="5"
                >
                  {{ getResultText(ra.resultState) }}
                </b-col>
              </b-row>
            </b-col>
            <!-- Üçüncü col  -->
            <b-col
              cols="12"
              md="4"
            >
              <b-row>
                <b-col
                  class="border text-danger"
                  cols="8"
                  md="8"
                >
                  Kişisel Özellik &nbsp; / &nbsp; Talep Oranı :
                </b-col>
                <b-col
                  class="border"
                  cols="4"
                  md="4"
                >
                  {{ ra.totalPersonalScore.toFixed(2) }} &nbsp;/ &nbsp;  {{ oran(ra.totalPersonalScore,r.evaluationStaffRequestSummaryViewModel.requestPersonalScore) }}
                </b-col>
              </b-row>
              <b-row>
                <b-col
                  class="border text-danger"
                  cols="8"
                  md="8"
                >
                  Mesleki Yeterlilik &nbsp; / &nbsp; Talep Oranı :
                </b-col>
                <b-col
                  class="border"
                  cols="4"
                  md="4"
                >
                  {{ ra.totalVocationalCompilanceScore.toFixed(2) }} &nbsp;/&nbsp;  {{ oran(ra.totalVocationalCompilanceScore, r.evaluationStaffRequestSummaryViewModel.requestVocationalCompilanceScore) }}
                </b-col>
              </b-row>
              <b-row>
                <b-col
                  class="border text-danger"
                  cols="8"
                  md="8"
                >
                  Temel Yetenek &nbsp; / &nbsp; Talep Oranı :
                </b-col>
                <b-col
                  class="border"
                  cols="4"
                  md="4"
                >
                  {{ ra.totalBasicAbilityScore.toFixed(2) }} &nbsp;/&nbsp;  {{ oran(ra.totalBasicAbilityScore, r.evaluationStaffRequestSummaryViewModel.requestBasicAbilityScore) }}
                </b-col>
              </b-row>
              <b-row>
                <b-col
                  class="border text-danger"
                  cols="8"
                  md="8"
                >
                  Teknik Yeterlilik &nbsp;&nbsp; / &nbsp;&nbsp; Talep Oranı :
                </b-col>
                <b-col
                  class="border"
                  cols="4"
                  md="4"
                >
                  {{ ra.totalTechnicalCompilanceScore.toFixed(2) }} &nbsp;/&nbsp;  {{ oran(ra.totalTechnicalCompilanceScore, r.evaluationStaffRequestSummaryViewModel.requestTechnicalCompilanceScore) }}
                </b-col>
              </b-row>
              <b-row>
                <b-col
                  class="border text-danger"
                  cols="8"
                  md="8"
                >
                  Risk &nbsp; / &nbsp; Talep Risk Oranı :
                </b-col>
                <b-col
                  class="border"
                  cols="4"
                  md="4"
                >
                  {{ ra.riskScore.toFixed(2) }} &nbsp;/&nbsp;  {{ oran(ra.riskScore, r.evaluationStaffRequestSummaryViewModel.requestRiskScore) }}
                </b-col>
              </b-row>
              <b-row>
                <b-col
                  class="border text-danger"
                  cols="8"
                  md="8"
                >
                  Karar Tarihi:
                </b-col>
                <b-col
                  class="border"
                  cols="4"
                  md="4"
                >
                  {{ strToDate(ra.resultDate) }}
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <!-- Not -->
          <b-row
            v-if="ra.redirectionState==1 || ra.redirectionState==2"
            class="border"
          >
            <b-col>
              <b-row class="text-left border">
                <b-col v-if="ra.redirectionState==1 || ra.redirectionState==2">
                  <label class="text-danger"> Yönlendirme Notu: </label>
                </b-col>
              </b-row>
              <b-row class="text-left border text-small">
                <b-col v-if="ra.redirectionState==1 || ra.redirectionState==2">
                  <p style="font-size:10px;">
                    {{ ra.redirectionNote }}
                  </p>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <!-- Olumsuz Neden -->
          <b-row>
            <b-col
              v-if="ra.redirectionState==1 || ra.redirectionState==2"
              class=" border"
              cols="6"
              md="4"
            >
              <label class="text-danger"> Olumsuz Nedeni: </label>
            </b-col>
            <b-col
              class="border"
            >
              {{ ra.resultNegativeText }}
            </b-col>
          </b-row>
        </b-col>
        <b-col md="2">
          <b-form-textarea
            id="e-textarea"
            v-model="ra.redirectionNote"
            type="text"
            placeholder="Not Yaz"
            rows="3"
            max-rows="2"
          />
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            size="sm"
            squared
            block
            class="mb-1"
            variant="relief-secondary"
            @click="onModalSaveNote(ra)"
          >
            <feather-icon
              icon="ArrowUpRightIcon"
              class="mr-30"
            />
            <span>Notu Kaydet </span>
          </b-button>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            size="sm"
            squared
            block
            class="mb-1"
            variant="relief-warning"
            @click="onGeriAl(ra)"
          >
            <feather-icon
              icon="ArrowUpRightIcon"
              class="mr-30"
            />
            <span>Geri Al</span>
          </b-button>
          <b-button
            v-if="ra.redirectionState==2"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            size="sm"
            squared
            block
            class="mb-1"
            variant="relief-primary"
            @click="onElenmeOzet(ra)"
          >
            <feather-icon
              icon="ArrowUpRightIcon"
              class="mr-30"
            />
            <span>Elenme Özeti </span>
          </b-button>
        </b-col>
      </b-row>
    </div>
    <!-- Elenme Özet Modal -->
    <b-modal
      id="elenme-modal"
      cancel-variant="outline-secondary"
      cancel-title="İptal"
      ok-title="Tamam"
      size="lg"
      centered
      rows="5"
      no-close-on-backdrop
      title="Eşleşmeme Nedenleri"
    >
      <b-row>
        <b-col>
          <b-row>
            <b-col
              cols="3"
              md="3"
              class="border font-weight-bold text-danger"
            >
              Kategori :
            </b-col>
            <b-col
              cols="3"
              md="3"
              class="border font-weight-bold"
            >
              Kişisel
            </b-col>
            <b-col
              cols="3"
              md="3"
              class="border font-weight-bold text-danger"
            >
              Sayı :
            </b-col>
            <b-col
              cols="3"
              md="3"
              class="border font-weight-bold"
            >
              {{ elemeNeden.filter(z => z.sayac !==0 && z.cat === 1).length }}
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <div
                v-for="(s ,t) in elemeNeden.filter(z => z.sayac !==0 && z.cat === 1)"
                :key="t"
              >
                <b-row>
                  <b-col
                    cols="12"
                    md="12"
                    class="border"
                  >
                    {{ s.tr }}
                  </b-col>
                </b-row>
              </div>
            </b-col>
          </b-row>
          <br>
          <b-row>
            <b-col
              cols="3"
              md="3"
              class="border font-weight-bold text-danger"
            >
              Kategori :
            </b-col>
            <b-col
              cols="3"
              md="3"
              class="border font-weight-bold"
            >
              Mesleki
            </b-col>
            <b-col
              cols="3"
              md="3"
              class="border font-weight-bold text-danger"
            >
              Sayı :
            </b-col>
            <b-col
              cols="3"
              md="3"
              class="border font-weight-bold"
            >
              {{ elemeNeden.filter(z => z.sayac !==0 && z.cat === 2).length }}
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <div
                v-for="(s ,t) in elemeNeden.filter(z => z.sayac !==0 && z.cat === 2)"
                :key="t"
              >
                <b-row>
                  <b-col
                    cols="12"
                    md="12"
                    class="border"
                  >
                    {{ s.tr }}
                  </b-col>
                </b-row>
              </div>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-modal>
    <!-- Karar Modal -->
    <b-modal
      id="karar-modal"
      cancel-variant="outline-secondary"
      cancel-title="İptal"
      ok-title="Kaydet"
      size="lg"
      centered
      rows="5"
      no-close-on-backdrop
      title="Karar"
      @ok="onModalSaveResultReason()"
    >
      <b-row>
        <b-col>
          <b-row>
            <b-col
              class="border font-weight-bold text-danger"
              cols="6"
              md="6"
              style="padding:5px"
            >
              Tarih
            </b-col>
            <b-col
              class="border"
              cols="6"
              md="6"
              style="padding:5px"
            >
              {{ getDate.substring(0, 10) }}
            </b-col>
          </b-row>
          <b-row>
            <b-col
              class="border font-weight-bold text-danger"
              cols="6"
              md="6"
              style="padding:5px"
            >
              Mülakatsız Eleme
            </b-col>
            <b-col
              class="border"
              cols="6"
              md="6"
              style="padding:5px"
            >
              <b-form-checkbox
                v-model="mulakatsiz"
                :value="true"
                class="custom-control-danger"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col
              class="border font-weight-bold text-danger"
              cols="12"
              md="6"
              style="padding:5px"
            >
              Görüşme Sonucu
            </b-col>
            <b-col
              class="border"
              cols="12"
              md="6"
              style="padding:5px"
            >
              <v-select
                v-model="resultState"
                :options="resultOptions"
                label="evaluationResultStateName"
                :reduce="item=> item.evaluationResultStateId "
                placeholder="Görüşme Sonucu Seçiniz"
                :disabled="mulakatsiz"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col
              class="border font-weight-bold text-danger"
              cols="12"
              md="6"
              style="padding:5px"
            >
              Olumsuzluk Nedeni
            </b-col>
            <b-col
              class="border"
              cols="12"
              md="6"
              style="padding:5px"
            >
              <v-select
                v-model="resultNegativeText"
                :options="negativetOptions"
                label="negativeResultDescription"
                :reduce="nrrId=> nrrId.negativeResultDescription"
                placeholder="Olumsuzluk Nedeni Seçiniz"
              />
            </b-col>
          </b-row>
          <b-row style="display:none">
            <b-col
              class="border font-weight-bold text-danger"
              cols="6"
              md="6"
              style="padding:5px"
            >
              Açıklama
            </b-col>
            <b-col
              class="border"
              cols="6"
              md="6"
              style="padding:5px"
            >
              SQ
            </b-col>
          </b-row>
          <b-row style="display:none">
            <b-col
              class="border font-weight-bold text-danger"
              cols="6"
              md="6"
              style="padding:5px"
            >
              Aylık Net Ücret
            </b-col>
            <b-col
              class="border"
              cols="6"
              md="6"
              style="padding:5px"
            >
              SQ
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-modal>
    <!-- CV Puan Modal -->
    <b-modal
      id="cvpuan-modal"
      cancel-variant="outline-secondary"
      cancel-title="Kapat"
      ok-title="Tamam"
      size="lg"
      centered
      rows="5"
      no-close-on-backdrop
      title="Toplam CV Puan"
    >
      <b-row>
        <b-col>
          <b-row>
            <b-col
              class="border font-weight-bold text-danger"
              cols="6"
              md="6"
              style="padding:5px"
            >
              Kişisel Özellik Toplam
            </b-col>
            <b-col
              class="border"
              cols="6"
              md="6"
              style="padding:5px"
            >
              {{ totalPersonalScore.toFixed(2) }}
            </b-col>
          </b-row>
          <b-row>
            <b-col
              class="border font-weight-bold text-danger"
              cols="6"
              md="6"
              style="padding:5px"
            >
              Mesleki Tecrübe Toplam
            </b-col>
            <b-col
              class="border"
              cols="6"
              md="6"
              style="padding:5px"
            >
              {{ totalBasicAbilityScore.toFixed(2) }}
            </b-col>
          </b-row>
          <b-row>
            <b-col
              class="border font-weight-bold text-danger"
              cols="6"
              md="6"
              style="padding:5px"
            >
              Temel Yetenek Toplam
            </b-col>
            <b-col
              class="border"
              cols="6"
              md="6"
              style="padding:5px"
            >
              {{ totalVocationalCompilanceScore.toFixed(2) }}
            </b-col>
          </b-row>
          <b-row>
            <b-col
              class="border font-weight-bold text-danger"
              cols="6"
              md="6"
              style="padding:5px"
            >
              Teknik Yeterlilik Toplam
            </b-col>
            <b-col
              class="border"
              cols="6"
              md="6"
              style="padding:5px"
            >
              {{ totalTechnicalCompilanceScore.toFixed(2) }}
            </b-col>
          </b-row>
          <b-row>
            <b-col
              class="border font-weight-bold text-danger"
              cols="6"
              md="6"
              style="padding:5px"
            >
              Risk Toplam
            </b-col>
            <b-col
              class="border"
              cols="6"
              md="6"
              style="padding:5px"
            >
              {{ riskScore.toFixed(2) }}
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>
<script>
import {
  BButton, VBModal, BRow, BCol, BFormGroup, BFormCheckbox, BFormTextarea,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import * as apiTalep from '@/api/islemler/firmRequest'
import * as apiCert from '@/api/islemler/staffRequests/staffRequestCertifications'
import * as apiComp from '@/api/islemler/staffRequests/staffRequestComputers'
import * as apiLang from '@/api/islemler/staffRequests/staffRequestForeignLanguages'
import * as apiExp from '@/api/islemler/staffRequests/staffRequestPositionExperiences'
import * as apiNRR from '@/api/tanimlar/VKT/NegativeResultReasons'
import * as apiQua from '@/api/islemler/staffRequests/staffRequestQualifications'
import * as apiSec from '@/api/islemler/staffRequests/staffRequestSectorExperiences'
import * as apiApl from '@/api/islemler/applicant/applicantEducation'
// import * as apiFirm from '@/api/islemler/firminventory'
import * as apiEval from '@/api/islemler/Evaluation'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BButton,
    // AppCollapse,
    // AppCollapseItem,
    BRow,
    BCol,
    // BBadge,
    // BForm,
    BFormTextarea,
    BFormGroup,
    BFormCheckbox,
    // BFormInput,
    // BFormSelect,
    vSelect,
  },
  directives: {
    Ripple,
    'b-modal': VBModal,
  },

  data() {
    return {
      firmStaffRequestId: this.$route.params.firmStaffRequestId,
      firmRequest: {},
      tblbaslik: {
        eslesen: 0, yonlendirilen: 0, olumlu: 0, bekleyen: 0, olumsuz: 0, elenen: 0, gereksay: 0, onemlisay: 0, farketmezsay: 0, kyu: 0, myu: 0, temyu: 0, tekyu: 0, rsku: 0, reqkyu: 0, reqmyu: 0, reqtem: 0, reqtek: 0, reqrsk: 0,
      },
      elemeNeden: [
        {
          cat: 1, en: 'GenderPoints', tr: 'Cinisyet', sayac: 0,
        },
        {
          cat: 1, en: 'MaritalStatusPoints', tr: 'Medeni hal', sayac: 0,
        },
        {
          cat: 1, en: 'BirthDatePoints', tr: 'Yaşı', sayac: 0,
        },
        {
          cat: 1, en: 'HeightCmPoints', tr: 'Boyu', sayac: 0,
        },
        {
          cat: 1, en: 'WeightKgPoints', tr: 'Kilosu', sayac: 0,
        },
        {
          cat: 1, en: 'TownPoints', tr: 'İlçe uyumsuzluğu', sayac: 0,
        },
        {
          cat: 1, en: 'MilitaryStatusPoints', tr: 'Askerlik durumu', sayac: 0,
        },
        {
          cat: 1, en: 'IsNotDisabledPoints', tr: 'Engellilik', sayac: 0,
        },
        {
          cat: 1, en: 'IsNotSmokerPoints', tr: 'Sigara kullanlımı', sayac: 0,
        },
        {
          cat: 1, en: 'CanTravelPoints', tr: 'Seyahat engeli', sayac: 0,
        },
        {
          cat: 1, en: 'HasWorkShiftsPoints', tr: 'Vardiyalı çalışamama', sayac: 0,
        },
        {
          cat: 1, en: 'HasReferencesPoints', tr: 'Referans eksiği', sayac: 0,
        },
        {
          cat: 1, en: 'HasNotCriminalRecordPoints', tr: 'Sabıka Kaydı', sayac: 0,
        },
        {
          cat: 1, en: 'CanDrivePoints', tr: 'Aktif araç kullanımı', sayac: 0,
        },
        {
          cat: 1, en: 'DriverLicenseTypePoints', tr: 'Ehliyet yetersizliği', sayac: 0,
        },
        {
          cat: 2, en: 'EducationLevelPoints', tr: 'Tahsil seviyesi', sayac: 0,
        },
        {
          cat: 2, en: 'VocationCollegePoints', tr: 'Meslek yüksek okulu bölümü', sayac: 0,
        },
        {
          cat: 2, en: 'VocationHighSchoolPoints', tr: 'Meslek lisesi bölümü', sayac: 0,
        },
        {
          cat: 2, en: 'UniversityPoints', tr: 'Üniversite bölümü', sayac: 0,
        },
        {
          cat: 2, en: 'PostgraduatePoints', tr: 'Yüksek lisans bölümü', sayac: 0,
        },
        {
          cat: 2, en: 'ComputerKnowledge1Points', tr: 'Bilgisayar bilgisi 1', sayac: 0,
        },
        {
          cat: 2, en: 'ComputerKnowledge2Points', tr: 'Bilgisayar bilgisi 2', sayac: 0,
        },
        {
          cat: 2, en: 'ComputerKnowledge3Points', tr: 'Bilgisayar bilgisi 3', sayac: 0,
        },
        {
          cat: 2, en: 'ComputerKnowledge4Points', tr: 'Bilgisayar bilgisi 4', sayac: 0,
        },
        {
          cat: 2, en: 'ComputerKnowledge5Points', tr: 'Bilgisayar bilgisi 5', sayac: 0,
        },
        {
          cat: 2, en: 'LanguageKnowLedge1Points', tr: 'Yabancı dil bilgisi 1', sayac: 0,
        },
        {
          cat: 2, en: 'LanguageKnowledge2Points', tr: 'Yabancı dil bilgisi 2', sayac: 0,
        },
        {
          cat: 2, en: 'LanguageKnowledge3Points', tr: 'Yabancı dil bilgisi 3', sayac: 0,
        },
        {
          cat: 2, en: 'LanguageKnowledge4Points', tr: 'Yabancı dil bilgisi 4', sayac: 0,
        },
        {
          cat: 2, en: 'LanguageKnowledge5Points', tr: 'Yabancı dil bilgisi 5', sayac: 0,
        },
        {
          cat: 2, en: 'Certificate1Points', tr: 'Sertifika 1 eksik', sayac: 0,
        },
        {
          cat: 2, en: 'Certificate2Points', tr: 'Sertifika 2 eksik', sayac: 0,
        },
        {
          cat: 2, en: 'Certificate3Points', tr: 'Sertifika 3 eksik', sayac: 0,
        },
        {
          cat: 2, en: 'Certificate4Points', tr: 'Sertifika 4 eksik', sayac: 0,
        },
        {
          cat: 2, en: 'Certificate5Points', tr: 'Sertifika 5 eksik', sayac: 0,
        },
        {
          cat: 2, en: 'TotalExperienceYearPoints', tr: 'Toplam tecrübe yılı', sayac: 0,
        },
        {
          cat: 2, en: 'SectorExperience1Points', tr: 'Sektör 1 uyumsuz', sayac: 0,
        },
        {
          cat: 2, en: 'SectorExperience2Points', tr: 'Sektör 2 uyumsuz', sayac: 0,
        },
        {
          cat: 2, en: 'SectorExperience3Points', tr: 'Sektör 3 uyumsuz', sayac: 0,
        },
        {
          cat: 2, en: 'SectorExperience4Points', tr: 'Sektör 4 uyumsuz', sayac: 0,
        },
        {
          cat: 2, en: 'SectorExperience5Points', tr: 'Sektör 5 uyumsuz', sayac: 0,
        },
        {
          cat: 2, en: 'PositionExperience1Points', tr: 'Pozisyon 1 tecrübesi eksik', sayac: 0,
        },
        {
          cat: 2, en: 'PositionExperience2Points', tr: 'Pozisyon 2 tecrübesi eksik', sayac: 0,
        },
        {
          cat: 2, en: 'PositionExperience3Points', tr: 'Pozisyon 3 tecrübesi eksik', sayac: 0,
        },
        {
          cat: 2, en: 'PositionExperience4Points', tr: 'Pozisyon 4 tecrübesi eksik', sayac: 0,
        },
        {
          cat: 2, en: 'PositionExperience5Points', tr: 'Pozisyon 5 tecrübesi eksik', sayac: 0,
        },
        {
          cat: 2, en: 'NumericalAbilityPoints', tr: 'Sayısal yetenek', sayac: 0,
        },
        {
          cat: 2, en: 'AnalyticalAbilityPoints', tr: 'Analitik düşünme', sayac: 0,
        },
        {
          cat: 2, en: 'AttentionAbilityPoints', tr: 'Dikkat yeteneği', sayac: 0,
        },
        {
          cat: 2, en: 'LearnAbilityPoints', tr: 'Öğrenebilme yeteneği', sayac: 0,
        },
        {
          cat: 2, en: 'ApplyingAbilityPoints', tr: 'Öğrendiğini uygulayabilme', sayac: 0,
        },
        {
          cat: 2, en: 'InnovationAbilityPoints', tr: 'Yenilik ve değişime açıklık', sayac: 0,
        },
        {
          cat: 2, en: 'CommunicationAbilityPoints', tr: 'İletişim ve kendini geliştirme', sayac: 0,
        },
        {
          cat: 2, en: 'SolutionAbilityPoints', tr: 'Problem çözebilme', sayac: 0,
        },
        {
          cat: 2, en: 'PlanningAbilityPoints', tr: 'Planlama', sayac: 0,
        },
        {
          cat: 2, en: 'CoordinationAbilityPoints', tr: 'Koordinasyon', sayac: 0,
        },
        {
          cat: 2, en: 'ObeyingAbilityPoints', tr: 'Emir komuta', sayac: 0,
        },
        {
          cat: 2, en: 'ControllingAbilityPoints', tr: 'Kontrol denetim', sayac: 0,
        },
        {
          cat: 2, en: 'DesignAbilityPoints', tr: 'Tasarım Kurgu', sayac: 0,
        },
        {
          cat: 2, en: 'RepresentationAbilityPoints', tr: 'Temsil', sayac: 0,
        },
        {
          cat: 2, en: 'FinancialRiskPoints', tr: 'Mali risk', sayac: 0,
        },
        {
          cat: 2, en: 'LegalRiskPoints', tr: 'Yasal risk', sayac: 0,
        },
        {
          cat: 2, en: 'MedicalRiskPoints', tr: 'Sağlık riski', sayac: 0,
        },
        {
          cat: 2, en: 'LifeRiskPoints', tr: 'Hayati risk', sayac: 0,
        },
      ],
      evalutationStaffingId: 0,
      applicantId: 0,
      redirectionNote: '',
      applicantName: '',
      redirectionState: 0,
      requestPosition: '',
      pageLength: 15,
      list: [],
      deger: true,
      sonuc: false,
      mulakatsiz: false,
      searchTerm: '',
      requestScores: [],
      r: this.$route.params.r,
      picture: '',
      sortSelected: { title: 'Kişisel Özellik', value: 'totalPersonalScore' },
      sortOption: [
        { title: 'Kişisel Özellik', value: 'totalPersonalScore' },
        { title: 'Mesleki Yeterlilik', value: 'totalVocationalCompilanceScore' },
        { title: 'Temel Yetenek', value: 'totalBasicAbilityScore' },
        { title: 'Teknik Yeterlilik', value: 'totalTechnicalCompilanceScore' },
        { title: 'Risk', value: 'riskScore' },
      ],
      resultOptions: [],
      resultDate: '',
      resultNegativeText: '',
      resultState: 0,
      resultText: '',
      openposition: {},
      retval: [],
      say: 0,
      adaytalep: [],
      adaybilgi: { essay: 0, yonsay: 0 },
      durum: true,
      gelen: [],
      gelfiltresiz: [],
      getDate: new Date().toLocaleString(),
      negativetOptions: [],
      seciliEvOpId: 0,
      adaycv: [],
      riskScore: 0,
      totalTechnicalCompilanceScore: 0,
      totalPersonalScore: 0,
      totalBasicAbilityScore: 0,
      totalVocationalCompilanceScore: 0,
      rDate: '',
    }
  },
  watch: {
    sortSelected() {
      this.gelen.sort((a, b) => b[`${this.sortSelected}`] - a[`${this.sortSelected}`])
    },
    sonuc() {
      if (this.sonuc) {
        this.gelfiltresiz = this.gelen
        this.gelen = this.gelen.filter(rel => rel.resultState !== 0)
      } else {
        this.gelen = this.gelfiltresiz
      }
    },
  },
  beforeMount() {
    this.getFirmRequest()
    this.getSRequest()
  },
  mounted() {
    this.resultStateText()
    this.getnegativetOptions()
    this.onResultBtnClicked()
  },
  methods: {
    getFirmRequest() {
      let cert = {}
      let comp = {}
      let lang = {}
      let exp = {}
      let qua = {}
      let sec = {}
      apiTalep.GetStaffRequestById(this.firmStaffRequestId).then(req => {
        this.firmRequest = req.data.entity
        apiEval.EvaluationStaffRequestSummary(this.firmStaffRequestId).then(ret => {
          const reqSum = ret.data.entity
          let toplamkyu = 0
          let toplammyu = 0
          let toplamtemyu = 0
          let toplamtekyu = 0
          let toplamrsku = 0
          this.tblbaslik.eslesen = reqSum.evaluationOpenPositionViewModels.filter(x => x.applicant.status === 1).length
          this.tblbaslik.yonlendirilen = reqSum.evaluationOpenPositionViewModels.filter(x => x.redirectionState === 1 || x.redirectionState === 2).length
          this.tblbaslik.bekleyen = reqSum.evaluationOpenPositionViewModels.filter(x => x.redirectionState === 1 || x.redirectionState === 2).filter(rel => rel.resultState === 1 || rel.resultState === 7 || rel.resultState === 0).length
          this.tblbaslik.olumlu = reqSum.evaluationOpenPositionViewModels.filter(rel => rel.resultState === 5 || rel.resultState === 6).length
          this.tblbaslik.olumsuz = reqSum.evaluationOpenPositionViewModels.filter(rel => rel.resultState === 2 || rel.resultState === 3 || rel.resultState === 4).length
          reqSum.evaluationOpenPositionViewModels.forEach(aday => {
            let rej = 0
            rej += aday.personalRejectReasons.length > 0 ? 1 : 0
            rej += aday.vocationalRejectReasons.length > 0 ? 1 : 0
            if (aday.applicant.status === 0) {
              rej = 0
            }
            this.neden(aday.personalRejectReasons)
            this.neden(aday.vocationalRejectReasons)
            toplamkyu += aday.totalPersonalScore
            toplammyu += aday.totalVocationalCompilanceScore
            toplamtemyu += aday.totalBasicAbilityScore
            toplamtekyu += aday.totalTechnicalCompilanceScore
            toplamrsku += aday.riskScore
            this.tblbaslik.elenen += rej > 0 ? 1 : 0
          })
          this.tblbaslik.kyu = toplamkyu !== 0 || this.tblbaslik.yonlendirilen !== 0 ? toplamkyu / this.tblbaslik.yonlendirilen : 0
          this.tblbaslik.myu = toplammyu !== 0 || this.tblbaslik.yonlendirilen !== 0 ? toplammyu / this.tblbaslik.yonlendirilen : 0
          this.tblbaslik.temyu = toplamtemyu !== 0 || this.tblbaslik.yonlendirilen !== 0 ? toplamtemyu / this.tblbaslik.yonlendirilen : 0
          this.tblbaslik.tekyu = toplamtekyu !== 0 || this.tblbaslik.yonlendirilen !== 0 ? toplamtekyu / this.tblbaslik.yonlendirilen : 0
          this.tblbaslik.rsku = toplamrsku !== 0 || this.tblbaslik.yonlendirilen !== 0 ? toplamrsku / this.tblbaslik.yonlendirilen : 0

          this.tblbaslik.reqkyu = reqSum.evaluationStaffRequestSummaryViewModel.requestPersonalScore
          this.tblbaslik.reqmyu = reqSum.evaluationStaffRequestSummaryViewModel.requestVocationalCompilanceScore
          this.tblbaslik.reqtem = reqSum.evaluationStaffRequestSummaryViewModel.requestBasicAbilityScore
          this.tblbaslik.reqtek = reqSum.evaluationStaffRequestSummaryViewModel.requestTechnicalCompilanceScore
          this.tblbaslik.reqrsk = reqSum.evaluationStaffRequestSummaryViewModel.requestRiskScore
          // reqtem: 0, reqtek: 0, reqrsk: 0,
        })
        apiCert.GetStaffRequestCeritificationByRequestId(this.firmStaffRequestId).then(crt => {
          cert = crt.data.entity
          apiComp.GetStaffRequestComputerByRequestId(this.firmStaffRequestId).then(cmp => {
            comp = cmp.data.entity
            apiLang.GetStaffRequestForeignLanguagesByRequestId(this.firmStaffRequestId).then(lng => {
              lang = lng.data.entity
              apiExp.GetStaffRequestPositionExperiencesByRequestId(this.firmStaffRequestId).then(ex => {
                exp = ex.data.entity
                apiQua.GetStaffQualificationByRequestId(this.firmStaffRequestId).then(qu => {
                  qua = qu.data.entity
                  apiSec.GetStaffRequestSectorExperienceByRequestId(this.firmStaffRequestId).then(sc => {
                    sec = sc.data.entity
                    this.tblbaslik.gereksay += sec.sectorImportanceLevel1Id === 3 ? 1 : 0
                    this.tblbaslik.gereksay += sec.sectorImportanceLevel2Id === 3 ? 1 : 0
                    this.tblbaslik.gereksay += sec.sectorImportanceLevel3Id === 3 ? 1 : 0
                    this.tblbaslik.gereksay += sec.sectorImportanceLevel4Id === 3 ? 1 : 0
                    this.tblbaslik.gereksay += sec.sectorImportanceLevel5Id === 3 ? 1 : 0

                    this.tblbaslik.onemlisay += sec.sectorImportanceLevel1Id === 2 ? 1 : 0
                    this.tblbaslik.onemlisay += sec.sectorImportanceLevel2Id === 2 ? 1 : 0
                    this.tblbaslik.onemlisay += sec.sectorImportanceLevel3Id === 2 ? 1 : 0
                    this.tblbaslik.onemlisay += sec.sectorImportanceLevel4Id === 2 ? 1 : 0
                    this.tblbaslik.onemlisay += sec.sectorImportanceLevel5Id === 2 ? 1 : 0

                    this.tblbaslik.farketmezsay += sec.sectorImportanceLevel1Id === 1 ? 1 : 0
                    this.tblbaslik.farketmezsay += sec.sectorImportanceLevel2Id === 1 ? 1 : 0
                    this.tblbaslik.farketmezsay += sec.sectorImportanceLevel3Id === 1 ? 1 : 0
                    this.tblbaslik.farketmezsay += sec.sectorImportanceLevel4Id === 1 ? 1 : 0
                    this.tblbaslik.farketmezsay += sec.sectorImportanceLevel5Id === 1 ? 1 : 0
                  })
                  this.tblbaslik.gereksay += qua.educationImportanceLevelId === 3 ? 1 : 0
                  this.tblbaslik.gereksay += qua.vocationalHighSchoolImportanceLevelId === 3 ? 1 : 0
                  this.tblbaslik.gereksay += qua.vocationalCollegeImportanceLevelId === 3 ? 1 : 0
                  this.tblbaslik.gereksay += qua.universityImportanceLevelId === 3 ? 1 : 0
                  this.tblbaslik.gereksay += qua.postgraduateImportanceLevelId === 3 ? 1 : 0
                  this.tblbaslik.gereksay += qua.yearsOfExperienceImportanceLevelId === 3 ? 1 : 0

                  this.tblbaslik.onemlisay += qua.educationImportanceLevelId === 2 ? 1 : 0
                  this.tblbaslik.onemlisay += qua.vocationalHighSchoolImportanceLevelId === 2 ? 1 : 0
                  this.tblbaslik.onemlisay += qua.vocationalCollegeImportanceLevelId === 2 ? 1 : 0
                  this.tblbaslik.onemlisay += qua.universityImportanceLevelId === 2 ? 1 : 0
                  this.tblbaslik.onemlisay += qua.postgraduateImportanceLevelId === 2 ? 1 : 0
                  this.tblbaslik.onemlisay += qua.yearsOfExperienceImportanceLevelId === 2 ? 1 : 0

                  this.tblbaslik.farketmezsay += qua.educationImportanceLevelId === 1 ? 1 : 0
                  this.tblbaslik.farketmezsay += qua.vocationalHighSchoolImportanceLevelId === 1 ? 1 : 0
                  this.tblbaslik.farketmezsay += qua.vocationalCollegeImportanceLevelId === 1 ? 1 : 0
                  this.tblbaslik.farketmezsay += qua.universityImportanceLevelId === 1 ? 1 : 0
                  this.tblbaslik.farketmezsay += qua.postgraduateImportanceLevelId === 1 ? 1 : 0
                  this.tblbaslik.farketmezsay += qua.yearsOfExperienceImportanceLevelId === 1 ? 1 : 0
                })
                this.tblbaslik.gereksay += exp.positionImportanceLevel1Id === 3 ? 1 : 0
                this.tblbaslik.gereksay += exp.positionImportanceLevel2Id === 3 ? 1 : 0
                this.tblbaslik.gereksay += exp.positionImportanceLevel3Id === 3 ? 1 : 0
                this.tblbaslik.gereksay += exp.positionImportanceLevel4Id === 3 ? 1 : 0
                this.tblbaslik.gereksay += exp.positionImportanceLevel5Id === 3 ? 1 : 0

                this.tblbaslik.onemlisay += exp.positionImportanceLevel1Id === 2 ? 1 : 0
                this.tblbaslik.onemlisay += exp.positionImportanceLevel2Id === 2 ? 1 : 0
                this.tblbaslik.onemlisay += exp.positionImportanceLevel3Id === 2 ? 1 : 0
                this.tblbaslik.onemlisay += exp.positionImportanceLevel4Id === 2 ? 1 : 0
                this.tblbaslik.onemlisay += exp.positionImportanceLevel5Id === 2 ? 1 : 0

                this.tblbaslik.farketmezsay += exp.positionImportanceLevel1Id === 1 ? 1 : 0
                this.tblbaslik.farketmezsay += exp.positionImportanceLevel2Id === 1 ? 1 : 0
                this.tblbaslik.farketmezsay += exp.positionImportanceLevel3Id === 1 ? 1 : 0
                this.tblbaslik.farketmezsay += exp.positionImportanceLevel4Id === 1 ? 1 : 0
                this.tblbaslik.farketmezsay += exp.positionImportanceLevel5Id === 1 ? 1 : 0
              })
              this.tblbaslik.gereksay += lang.languageImportanceLevel1Id === 3 ? 1 : 0
              this.tblbaslik.gereksay += lang.languageImportanceLevel2Id === 3 ? 1 : 0
              this.tblbaslik.gereksay += lang.languageImportanceLevel3Id === 3 ? 1 : 0
              this.tblbaslik.gereksay += lang.languageImportanceLevel4Id === 3 ? 1 : 0
              this.tblbaslik.gereksay += lang.languageImportanceLevel5Id === 3 ? 1 : 0

              this.tblbaslik.onemlisay += lang.languageImportanceLevel1Id === 2 ? 1 : 0
              this.tblbaslik.onemlisay += lang.languageImportanceLevel2Id === 2 ? 1 : 0
              this.tblbaslik.onemlisay += lang.languageImportanceLevel3Id === 2 ? 1 : 0
              this.tblbaslik.onemlisay += lang.languageImportanceLevel4Id === 2 ? 1 : 0
              this.tblbaslik.onemlisay += lang.languageImportanceLevel5Id === 2 ? 1 : 0

              this.tblbaslik.farketmezsay += lang.languageImportanceLevel1Id === 1 ? 1 : 0
              this.tblbaslik.farketmezsay += lang.languageImportanceLevel2Id === 1 ? 1 : 0
              this.tblbaslik.farketmezsay += lang.languageImportanceLevel3Id === 1 ? 1 : 0
              this.tblbaslik.farketmezsay += lang.languageImportanceLevel4Id === 1 ? 1 : 0
              this.tblbaslik.farketmezsay += lang.languageImportanceLevel5Id === 1 ? 1 : 0
            })
            this.tblbaslik.gereksay += comp.computerImportanceLevel1Id === 3 ? 1 : 0
            this.tblbaslik.gereksay += comp.computerImportanceLevel2Id === 3 ? 1 : 0
            this.tblbaslik.gereksay += comp.computerImportanceLevel3Id === 3 ? 1 : 0
            this.tblbaslik.gereksay += comp.computerImportanceLevel4Id === 3 ? 1 : 0
            this.tblbaslik.gereksay += comp.computerImportanceLevel5Id === 3 ? 1 : 0

            this.tblbaslik.onemlisay += comp.computerImportanceLevel1Id === 2 ? 1 : 0
            this.tblbaslik.onemlisay += comp.computerImportanceLevel2Id === 2 ? 1 : 0
            this.tblbaslik.onemlisay += comp.computerImportanceLevel3Id === 2 ? 1 : 0
            this.tblbaslik.onemlisay += comp.computerImportanceLevel4Id === 2 ? 1 : 0
            this.tblbaslik.onemlisay += comp.computerImportanceLevel5Id === 2 ? 1 : 0

            this.tblbaslik.farketmezsay += comp.computerImportanceLevel1Id === 1 ? 1 : 0
            this.tblbaslik.farketmezsay += comp.computerImportanceLevel2Id === 1 ? 1 : 0
            this.tblbaslik.farketmezsay += comp.computerImportanceLevel3Id === 1 ? 1 : 0
            this.tblbaslik.farketmezsay += comp.computerImportanceLevel4Id === 1 ? 1 : 0
            this.tblbaslik.farketmezsay += comp.computerImportanceLevel5Id === 1 ? 1 : 0
          })
          this.tblbaslik.gereksay += cert.certificateImportanceLevel1Id === 3 ? 1 : 0
          this.tblbaslik.gereksay += cert.certificateImportanceLevel2Id === 3 ? 1 : 0
          this.tblbaslik.gereksay += cert.certificateImportanceLevel3Id === 3 ? 1 : 0
          this.tblbaslik.gereksay += cert.certificateImportanceLevel4Id === 3 ? 1 : 0
          this.tblbaslik.gereksay += cert.certificateImportanceLevel5Id === 3 ? 1 : 0

          this.tblbaslik.onemlisay += cert.certificateImportanceLevel1Id === 2 ? 1 : 0
          this.tblbaslik.onemlisay += cert.certificateImportanceLevel2Id === 2 ? 1 : 0
          this.tblbaslik.onemlisay += cert.certificateImportanceLevel3Id === 2 ? 1 : 0
          this.tblbaslik.onemlisay += cert.certificateImportanceLevel4Id === 2 ? 1 : 0
          this.tblbaslik.onemlisay += cert.certificateImportanceLevel5Id === 2 ? 1 : 0

          this.tblbaslik.farketmezsay += cert.certificateImportanceLevel1Id === 1 ? 1 : 0
          this.tblbaslik.farketmezsay += cert.certificateImportanceLevel2Id === 1 ? 1 : 0
          this.tblbaslik.farketmezsay += cert.certificateImportanceLevel3Id === 1 ? 1 : 0
          this.tblbaslik.farketmezsay += cert.certificateImportanceLevel4Id === 1 ? 1 : 0
          this.tblbaslik.farketmezsay += cert.certificateImportanceLevel5Id === 1 ? 1 : 0
        })
        this.tblbaslik.gereksay += this.firmRequest.genderImportanceLevelId === 3 ? 1 : 0
        this.tblbaslik.gereksay += this.firmRequest.maritalImportanceLevelId === 3 ? 1 : 0
        this.tblbaslik.gereksay += this.firmRequest.ageImportanceLevelId === 3 ? 1 : 0
        this.tblbaslik.gereksay += this.firmRequest.weightImportanceLevelId === 3 ? 1 : 0
        this.tblbaslik.gereksay += this.firmRequest.heightImportanceLevelId === 3 ? 1 : 0
        this.tblbaslik.gereksay += this.firmRequest.militaryStatusImportanceLevelId === 3 ? 1 : 0
        this.tblbaslik.gereksay += this.firmRequest.townsImportanceLevelId === 3 ? 1 : 0
        this.tblbaslik.gereksay += this.firmRequest.isNotDisabledImportanceLevelId === 3 ? 1 : 0
        this.tblbaslik.gereksay += this.firmRequest.isNotSmokerImportanceLevelId === 3 ? 1 : 0
        this.tblbaslik.gereksay += this.firmRequest.canTravelImportanceLevelId === 3 ? 1 : 0
        this.tblbaslik.gereksay += this.firmRequest.hasWorkShiftsImportanceLevelId === 3 ? 1 : 0
        this.tblbaslik.gereksay += this.firmRequest.hasReferencesImportanceLevelId === 3 ? 1 : 0
        this.tblbaslik.gereksay += this.firmRequest.hasNotCriminalRecordImportanceLevelId === 3 ? 1 : 0
        this.tblbaslik.gereksay += this.firmRequest.canDriveImportanceLevelId === 3 ? 1 : 0
        this.tblbaslik.gereksay += this.firmRequest.driverLicenseImportanceLevelId === 3 ? 1 : 0

        this.tblbaslik.onemlisay += this.firmRequest.genderImportanceLevelId === 2 ? 1 : 0
        this.tblbaslik.onemlisay += this.firmRequest.maritalImportanceLevelId === 2 ? 1 : 0
        this.tblbaslik.onemlisay += this.firmRequest.ageImportanceLevelId === 2 ? 1 : 0
        this.tblbaslik.onemlisay += this.firmRequest.weightImportanceLevelId === 2 ? 1 : 0
        this.tblbaslik.onemlisay += this.firmRequest.heightImportanceLevelId === 2 ? 1 : 0
        this.tblbaslik.onemlisay += this.firmRequest.militaryStatusImportanceLevelId === 2 ? 1 : 0
        this.tblbaslik.onemlisay += this.firmRequest.townsImportanceLevelId === 2 ? 1 : 0
        this.tblbaslik.onemlisay += this.firmRequest.isNotDisabledImportanceLevelId === 2 ? 1 : 0
        this.tblbaslik.onemlisay += this.firmRequest.isNotSmokerImportanceLevelId === 2 ? 1 : 0
        this.tblbaslik.onemlisay += this.firmRequest.canTravelImportanceLevelId === 2 ? 1 : 0
        this.tblbaslik.onemlisay += this.firmRequest.hasWorkShiftsImportanceLevelId === 2 ? 1 : 0
        this.tblbaslik.onemlisay += this.firmRequest.hasReferencesImportanceLevelId === 2 ? 1 : 0
        this.tblbaslik.onemlisay += this.firmRequest.hasNotCriminalRecordImportanceLevelId === 2 ? 1 : 0
        this.tblbaslik.onemlisay += this.firmRequest.canDriveImportanceLevelId === 2 ? 1 : 0
        this.tblbaslik.onemlisay += this.firmRequest.driverLicenseImportanceLevelId === 2 ? 1 : 0

        this.tblbaslik.farketmezsay += this.firmRequest.genderImportanceLevelId === 1 ? 1 : 0
        this.tblbaslik.farketmezsay += this.firmRequest.maritalImportanceLevelId === 1 ? 1 : 0
        this.tblbaslik.farketmezsay += this.firmRequest.ageImportanceLevelId === 1 ? 1 : 0
        this.tblbaslik.farketmezsay += this.firmRequest.weightImportanceLevelId === 1 ? 1 : 0
        this.tblbaslik.farketmezsay += this.firmRequest.heightImportanceLevelId === 1 ? 1 : 0
        this.tblbaslik.farketmezsay += this.firmRequest.militaryStatusImportanceLevelId === 1 ? 1 : 0
        this.tblbaslik.farketmezsay += this.firmRequest.townsImportanceLevelId === 1 ? 1 : 0
        this.tblbaslik.farketmezsay += this.firmRequest.isNotDisabledImportanceLevelId === 1 ? 1 : 0
        this.tblbaslik.farketmezsay += this.firmRequest.isNotSmokerImportanceLevelId === 1 ? 1 : 0
        this.tblbaslik.farketmezsay += this.firmRequest.canTravelImportanceLevelId === 1 ? 1 : 0
        this.tblbaslik.farketmezsay += this.firmRequest.hasWorkShiftsImportanceLevelId === 1 ? 1 : 0
        this.tblbaslik.farketmezsay += this.firmRequest.hasReferencesImportanceLevelId === 1 ? 1 : 0
        this.tblbaslik.farketmezsay += this.firmRequest.hasNotCriminalRecordImportanceLevelId === 1 ? 1 : 0
        this.tblbaslik.farketmezsay += this.firmRequest.canDriveImportanceLevelId === 1 ? 1 : 0
        this.tblbaslik.farketmezsay += this.firmRequest.driverLicenseImportanceLevelId === 1 ? 1 : 0
      })
    },
    async getSRequest() {
      await apiEval.EvaluationStaffRequestSummary(this.firmStaffRequestId).then(res => {
        this.r = res.data.entity
        this.r.evaluationOpenPositionViewModels.forEach(element => {
          apiEval.GetEvaluationOpenPositionsByApplicantId(element.applicant.applicantId).then(ret => {
            apiApl.GetApplicantEducationsByApplicantId(element.applicant.applicantId).then(edu => {
              Object.assign(element.applicant, { educationLevel: edu.data.entity.educationLevel.educationLevelDescription })
              let bolum = ''
              switch (edu.data.entity.educationLevel.educationLevelId) {
                case 6: bolum = edu.data.entity.vocationHighSchool
                  break
                case 7: bolum = edu.data.entity.vocationCollege
                  break
                case 8: bolum = edu.data.entity.university
                  break
                case 9: bolum = edu.data.entity.postgraduate
                  break
                default: bolum = ''
                  break
              }
              Object.assign(element.applicant, { education: bolum })
              apiEval.GetApplicantEvaluationCvPointsSummaryByApplicantId(element.applicant.applicantId).then(cv => {
                Object.assign(element.applicant, { totalPersonalScore: cv.data.entity.totalPersonalScore })
              })
              let essay = 0
              let yonsay = 0
              essay = ret.data === null ? 0 : ret.data.count
              yonsay = ret.data === null ? 0 : ret.data.list.filter(x => x.redirectionState === 1 || x.redirectionState === 2).length
              Object.assign(element.applicant, { evoluationCount: essay })
              Object.assign(element.applicant, { redirectionCount: yonsay })
            })
          })
        })
      })
      this.gelen = await this.r.evaluationOpenPositionViewModels
      this.gelen.sort((a, b) => b[`${this.sortSelected}`] - a[`${this.sortSelected}`])
    },
    getApplicantData(aid) {
      let say = 0
      apiEval.GetEvaluationOpenPositionsByApplicantId(aid).then(ret => {
        this.adaytalep = ret.data.list
        say = ret.data.count
        return say
      })
    },
    onDataGridItemClicked(params) {
      return params
    },
    resultStateText() {
      apiEval.GetEvaluationResultStates().then(res => {
        this.retval = res.data.list
      })
    },
    getResultText(id) {
      let sondurum = ''
      switch (id) {
        case 1: case 7: sondurum = 'Belirsiz'
          break
        case 2: case 3: case 4: sondurum = 'Olumsuz'
          break
        case 5: case 6: sondurum = 'Olumlu'
          break
        case 8: sondurum = 'Mülakatsız Eleme'
          break
        case 0: sondurum = ''
          break
        default: sondurum = 'Belirsiz'
      }
      return sondurum
    },
    onModalCancelled() {},
    strToDate(st) {
      if (st === undefined || st === null) return st
      if (st.length < 10) return st
      let yil = ''
      yil = st.substring(0, 4)
      let ay = ''
      ay = st.substring(5, 7)
      let gun = ''
      gun = st.substring(8, 10)
      return `${gun}.${ay}.${yil}`
    },
    neden(sebep) {
      // { en: 'GenderPoints', tr: 'Cinisyet', sayac: 0 },
      sebep.forEach(x => {
        this.elemeNeden.forEach((ob, i) => {
          if (ob.en === x) {
            this.elemeNeden[i].sayac += 1
          }
        })
      })
      // return true
    },
    oran(a, b) {
      if (a === 0.00) return ' - '
      if (b === 0.00) return ' - '
      const yuzde = (a / b) * 100
      return `${yuzde.toFixed(2).toString()} %`
    },
    onAdayBtnClicked(row) {
      this.$router.push({ name: 'aday-ozgecmis', params: { applicantId: row.applicant.applicantId, applicant: row.applicant } })
    },
    onGetFirmClicked(row) {
      this.$router.push({ name: 'firma-kart', params: { firmId: row.staffRequestSummaryViewModel.firmInfo.firmInfoId } })
    },
    onElenmeOzet() {
      this.$bvModal.show('elenme-modal')
    },
    onKarar(rw) {
      this.seciliEvOpId = rw.evalutationStaffingId
      this.$bvModal.show('karar-modal')
    },
    onCvPuanBtnClicked(rw) {
      apiEval.GetApplicantEvaluationCvPointsSummaryByApplicantId(rw.applicant.applicantId).then(cvp => {
        this.adaycv = cvp.data.entity
        this.totalBasicAbilityScore = this.adaycv.totalBasicAbilityScore
        this.totalPersonalScore = this.adaycv.totalPersonalScore
        this.totalTechnicalCompilanceScore = this.adaycv.totalTechnicalCompilanceScore
        this.totalVocationalCompilanceScore = this.adaycv.totalVocationalCompilanceScore
        this.riskScore = this.adaycv.riskScore
      })
      this.$bvModal.show('cvpuan-modal')
    },
    getnegativetOptions() {
      apiNRR.GetNegativeResultReasons().then(ret => {
        this.negativetOptions = ret.data.list
      })
    },
    onResultBtnClicked() {
      apiEval.GetEvaluationResultStates().then(res => {
        this.resultOptions = res.data.list.filter(x => x.evaluationResultStateId !== 8)
      })
    },
    onModalSaveNote(row) {
      apiEval.EvaluationAddNoteToOpenPositionItem(row.evalutationStaffingId, row.redirectionNote)
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Aday Not Ekle',
          text: 'Adaya not eklenmiştir.',
          icon: 'PlusSquareIcon',
          variant: 'success',
        },
      })
    },
    onGeriAl(row) {
      const rState = 0
      const d = new Date()
      const rDate = d.toISOString()
      const rText = ''
      apiEval.EvaluationAddResultToOpenPositionItem(row.evalutationStaffingId, rDate, rState, rText, '').then(() => {
        this.getSRequest()
      })
    },
    onModalSaveResultReason() {
      let rState = 8
      const d = new Date()
      let rDate = d.toISOString()
      let rText = ''
      let rNText = this.resultNegativeText
      if (this.seciliEvOpId === 0) return
      if (this.mulakatsiz) {
        rState = 8
        rDate = d.toISOString()
        rText = ''
        rNText = this.resultNegativeText
      } else {
        rState = this.resultState
        rDate = d.toISOString()
        rText = ''
        rNText = this.resultNegativeText
      }
      apiEval.EvaluationAddResultToOpenPositionItem(this.seciliEvOpId, rDate, rState, rText, rNText).then(() => {
        this.getSRequest()
      })
    },
  },
}
</script>
<style lang='scss'>
   @import '@core/scss/vue/libs/vue-select.scss';
</style>
